import { functions } from '../../firestore';

export default class {
  constructor() {
    this.pathGetDeployments = 'shepherd/getDeployments';
    this.pathDeployBuild = 'shepherd/setDeploymentImage';
  }

  deployBuild(deployment, image) {
    return new Promise((resolve, reject) => {
      const deployments = functions.httpsCallable(this.pathDeployBuild);
      deployments({
        deployment,
        image
      }).then((result) => {
        if (result.data) {
          resolve(result.data);
        } else {
          reject();
        }
      });
    });
  }

  getDeployments() {
    return new Promise((resolve, reject) => {
      const deployments = functions.httpsCallable(this.pathGetDeployments);
      deployments().then((result) => {
        resolve(result.data);
      }).catch(() => {
        reject();
      });
    });
  }
}
