<template>
  <div>
    <v-card>
      <v-card-title>
        Deployer List
        <v-spacer/>
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="deployment"
        :search="search"
      >
        <template v-slot:item.view="{ item }">
          <v-btn
            fab
            dark
            small
            color="primary"
            :to="{
                  name: 'deployer-detail',
                  params: { deploymentID: item.id }
                }">
            <v-icon dark>fas fa-eye</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-btn fab dark small color="primary" @click="deleteJob = item">
            <v-icon dark>far fa-trash-alt</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { db } from '../../../firestore';

export default {
  name: 'DeployerList',
  data() {
    return {
      deployment: [],
      headers: [
        {
          text: 'View',
          align: 'left',
          value: 'view',
        },
        {
          text: 'Deployment',
          align: 'left',
          value: 'name',
        },
        {
          text: 'Last Deployed',
          align: 'left',
          value: 'lastDeployed',
        },
      ],
      search: ''
    };
  },
  firestore: {
    deployment: db.collection('shepherd-deployment'),
  },
};
</script>
